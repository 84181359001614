import MHelper from './MHelper';
export default class Analytics {
  constructor(options) {
    if (!MHelper.ifSSR) {
      Object.assign(this, options);
      this.trackPage(window.location.pathname);

      if (
        window.localStorage && document.referrer &&
        document.referrer.includes(window.location.host) &&
        (Date.now() - +localStorage.getItem('szLastVisit')) < 3600000
      ) {
        this.pagesViewed = +localStorage.getItem('szPagesViewed') + 1;
        this.trackVisited();
      } else {
        this.pagesViewed = 1;
      }

      localStorage.setItem('szPagesViewed', this.pagesViewed);
      this.init();
    }
  };

  init = () => {
    for(let item in this.events) {
      let event = this.events[item];
      if (event.method)
        this[event.method]();
    }

    document.addEventListener('analytics', e => {
      this.test(e.detail.type, e.detail);
    });

    this.markLastVisit();
  };

  nextPage = path => {
    this.pagesViewed++;
    if (window.localStorage) {
      if ((Date.now() - +localStorage.getItem('szLastVisit')) >= 3600000) {
        this.pagesViewed = 1;
      }

      localStorage.setItem('szPagesViewed', this.pagesViewed);
    }

    this.trackPage(path);
    this.trackVisited();
    this.markLastVisit();
  };

  trackVisited = () => {
    if (this.pagesViewed >= 2 && this.pagesViewed <= 3) {
      this.trackConversion(`pages_${this.pagesViewed}`);
      this.trackEvent(`pages_${this.pagesViewed}`, undefined, undefined, 'Index');
    }
  }

  markLastVisit = () => {
    localStorage.setItem('szLastVisit', Date.now());
  }

  test = (type, detail) => {
    if (type in this.events) {
      let event = this.events[type];

      if (event.action)
        event.action.forEach((action, key) => {
          let label = ( event.label && detail) ? detail[event.label[key]] : "Click";
          let value = event.value ? event.value[key] : 0;
          let category = event.category ? event.category : this.category;
          this.trackEvent(action, label, value, category);
        });

      if (event.conversion)
        event.conversion.forEach((conversionItem) => {
          this.trackConversion(conversionItem);
          this.trackServer(conversionItem);
        });
    }
  };

  trackEvent = (action, label, value, category) => {
    window.gtag('event', action, {
      'event_label': label,
      'value': value,
      'event_category': category,
    });
  };

  trackPage = page => {
    window.gtag('config', this.gaId4, { 'page_path': page })
  };

  trackServer = conversionName => {
    const sendJson = JSON.stringify({
      locationUrl: window.location.href,
      conversions: [{conversion_name: conversionName}]
    })
    const headers = {type: 'application/json',};
    const blob = new Blob([sendJson], headers);
    navigator.sendBeacon('/stats/events', blob);
  }

  trackConversion = (val) => {
    if (this.gtagEventsMap && Object.keys(this.gtagEventsMap).length && this.gtagEventsMap[val]) {
      window.gtag('event', 'conversion', {
        'allow_custom_scripts': true,
        'send_to': this.gtagEventsMap[val],
      });
    }
  };
}
