import sendAnalyticsEvent from "./sendAnalyticsEvent";

const delayedEvents = () => {
  return {
    add: (event) => {
      if (!window.szDelayedEvents) window.szDelayedEvents = [];
      const isExists = window.szDelayedEvents.some(item => item.name === event.name);
      if (!isExists) {
        window.szDelayedEvents.push(event);
      }
    },
    send: () => {
      if (window.szDelayedEvents) {
        window.szDelayedEvents.forEach((event) => {
          sendAnalyticsEvent(event.name, event.detail);
        });
      }
    }
  }
}

export default delayedEvents;
